import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: {eq: "goldcoastlogo"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <LogoWrap as={Link} to="/">
      <Image fluid={data.file.childImageSharp.fluid} alt="logo" />
    </LogoWrap>
  )
}

export default Logo

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 36px;  

  @media screen and (max-width: 548px){
    margin: 200;
  }
`

const Image = styled(Img)`
  left: 0;
  border-radius: 0px;
  width: 200px;
  height: 100%;

  @media screen and (max-width: 820px){
    width: 100px;
    height: 100%;
  }
`
