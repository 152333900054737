/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import Footer from "./footer.js"
import Header from "./header.js"
import { GlobalStyle } from "./styles/globalstyles.js"
import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({children}) => {

  const [open, setOpen] = useState();

  return (
    <div className={open ? "menu-open" : "menu-close"}>
    <GlobalStyle />
      <Header open={open} setOpen={setOpen}/>
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout


