import {createGlobalStyle} from "styled-components"
import backgroundImageSand from "./sand_texture-bg.jpg"

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #gatsby-focus-wrapper {
    overflow-x: hidden;
  }

  body {
    height: 100%;
    background-image: url(${backgroundImageSand});
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Libre Baskerville', serif;
    color: #373A3C;
  }

  p, a, ul, li, span {
    font-family: 'Nunito', sans-serif;
    line-height: 1.6;
    font-size: 1.1rem;
    color: #373A3C;
  }

  .menu-open{
    transform: translateX(-355px);
    transition: all 0.5s ease-in-out;
    position: fixed;
  }

  .menu-close{
    transform: translateX(0);
    transition: all 0.25s ease-out;
  }
`