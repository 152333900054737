import React, {useState} from "react"
import styled from "styled-components"
import Logo from "../elements/logo"
import Menu from "../elements/menu.js"
import { Link } from "gatsby"
import {AiOutlineCloseCircle} from "react-icons/ai"
import {FaBars} from "react-icons/fa"


function Header({open, setOpen, }) {
  
  return (
    <>
      <Nav>
        <Logo />
        <NavFullScreen>
          <NavLink to="/reservations">Reservations</NavLink>
          <p>|</p>
          <MenuButton onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}>Menu</MenuButton>
        </NavFullScreen>
        <Bars onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}/>      
      </Nav>
      <Navigation className="navigation">
        <Close onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}/>
          <MenuWrapper onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}>
            <Menu />
          </MenuWrapper>
      </Navigation>
    </>
  )
}

export default Header

const Nav = styled.nav`
  background: #093656;
  border-bottom: 1px solid #fff;
  height: 117px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1400px) / 2);
  z-index: 100;
  position: relative;
`

const NavFullScreen = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5%;
  
  @media screen and (max-width: 868px) {
    display: none;
  }

  p{
    padding: 0 10px;
    margin: 0;
    color: #fff;
  }
`

const Navigation = styled.div`
    position: absolute;
    right: -355px;
    width: 350px;
    height: 100vh;
    top: 0;
    bottom: 0;
    background: #dedede;
    border-left: 1px solid #b5b5b5;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    padding-top: 30px;
    width: 355px;
    overflow: hidden;
`

const MenuButton = styled.button`
  background: transparent;
  border: none;
  color: #fff;
`

const NavLink = styled(Link)`
  padding-right: 5px;
  text-decoration: none;
  color: #fff;

  &:hover{
    text-decoration: none;
  }
`

const MenuWrapper = styled.div`
  padding-left: 25%;
  cursor: pointer;
`

const Close = styled(AiOutlineCloseCircle)`
  cursor: pointer;
  top: 0;
  right: 0;
  text-align: right;
  font-size: 2rem;
  margin-bottom: 3rem;
  margin-top: 1rem;
  margin-left: 1rem;
`

const Bars = styled(FaBars)`
  display: none;
  color: #fff;

  @media screen and (max-width: 868px){
    display: block;
    position: absolute;
    top: 20%;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`


