import React from 'react'
import styled from "styled-components"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import Logo from "../elements/logo"

const Footer = () => {
const data = useStaticQuery(graphql`
  query {
    file(name: {eq: "footerBlock-tripadvisor"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <FooterWrapper>
    <FooterContainer>
      <FooterLogoWrapper>
        <FooterLogo>
          <Logo />
        </FooterLogo>        
      </FooterLogoWrapper>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle to="/">Home</FooterLinkTitle>
            <FooterLink to="/aboutGoldCoast">About</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
            <FooterLink to="/location">Location</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle to="/reservations">Reservations</FooterLinkTitle>
            <FooterLink to="/cottages">Cottages</FooterLink>
            <FooterLink to="/rates">Rates</FooterLink>
            <FooterLink to="/gettingHere">Getting Here</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterBlockWrapper 
              as={Link} 
              to="https://www.tripadvisor.ca/Hotel_Review-g154942-d1473378-Reviews-Gold_Coast_Retreat-Tofino_Clayoquot_Sound_Alberni_Clayoquot_Regional_District_Vancouve.html"
              target="example"
              rel="noreferrer"
            >
                <Image fluid={data.file.childImageSharp.fluid} alt="Trip Advisor Review Link"/>
            </FooterBlockWrapper>
          </FooterLinkItems>  
        </FooterLinksWrapper>       
      </FooterLinksContainer>
    </FooterContainer>
    <BusinessLisenceInfo>
        <p>Copyright Gold Coast Retreat &copy; {new Date().getFullYear()}</p>
        <p>Tofino Business Lisence #20210221</p>
    </BusinessLisenceInfo>
    </FooterWrapper>
  )
}

export default Footer


const FooterWrapper = styled.div`
  background: #093656;
  display: flex;
  flex-direction: column;
`

const FooterContainer = styled.footer`
  padding: 5rem calc((100vw - 1100px) / 2);
  display: grid;
  grid-template-columns: 1fr 2fr;
  color: #fff;

  @media screen and (max-width: 820px){
    grid-template-columns: 1fr;
  }
`

const FooterLogo = styled.div`
  padding: 0;
`

const FooterLogoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const FooterLinksContainer = styled.div`
  @media screen and (max-width: 820px){
   
  }
`

const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
  }
`

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1rem;
`

const FooterLinkTitle = styled(Link)`
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #fff;

  &:hover {
    color: #848c91;
    transition: 0.3s ease-out;
    text-decoration: none;
  }
`

const FooterLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 16px;
  color: #fff;

  &:hover {
    color: #848c91;
    transition: 0.3s ease-out;
    text-decoration: none;
  }
`

const FooterBlockWrapper = styled.div`
  height: 100%;
  width: 250px; 
  border: 2px solid #569440; 
`

const Image = styled(Img)`
  width: 100%;  
  height: 100%;
`

const BusinessLisenceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #fff;
  }
  
`