import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {menuData} from "../data/menuData"

const Menu = () => {
  
  return (
    <>
      {menuData.map((item, index) => (
        <NavLink to={item.link} key={index}>
          {item.title}
        </NavLink>
      ))}
    </>
  )
}

export default Menu

const NavLink = styled(Link)`
  color: #000;
  display: flex;
  flex-direction: column;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: 1rem;

  &:hover{
    color: #093656;
  }
`
