export const menuData = [
  { title: "Home", link: "/" },
  { title: "Reservations", link: "/reservations" },
  { title: "Cottages", link: "/cottages" },
  { title: "Rates", link: "/rates" },
  { title: "About Gold Coast", link: "/aboutGoldCoast" },
  { title: "About Tofino", link: "/aboutTofino" },
  { title: "Gallery", link: "/gallery" },
  { title: "Contact", link: "/contact" },
  { title: "Location", link: "/location" },
  { title: "Getting Here", link: "/gettingHere" },
]